<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col>
          <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
            ><i class="el-icon-refresh-right"></i> 刷新</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" class="mtrTab">
            <el-table-column type="index" align="center" label="序号" width="45" />
            <el-table-column prop="remindContent" label="内容" />
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { shweAPI } from '@api/modules/shwe';
import helper from '@assets/js/helper.js';
import { recordAPI } from '@api/modules/record';
export default {
  name: 'ShwePush9002',
  data() {
    return {
      tableData: [],
      loading: true
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  methods: {
    initData() {
      this.loading = true;
      this.getPushList();
    },
    //获取信息
    getPushList() {
      recordAPI.getRecordRemindListByStatus().then(({ data }) => {
        this.tableData = data.data;
        this.loading = false;
      });
    },
    buttonRefresh() {
      this.initData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_completed {
  color: $color-secondary-text;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.mtrTab {
  margin-top: 10px;
  cursor: pointer;
}
</style>
